<template>
  <div v-if="loading" class="loading-wrap" :style="chartHeight">
    <Loading vertical color="#0C86FE">加载中...</Loading>
  </div>
  <div class="echarts" :style="chartHeight" v-else>
    <div class="scroll-wrap" v-if="isScroll && !isEmpty">
      <div class="echart" :id="echartsId" :style="{ width: scrollWidth, height: height + 'px' }"></div>
    </div>
    <div v-if="!isScroll && !isEmpty" class="echart" :id="echartsId"></div>
    <div v-if="isEmpty" class="empty">
      <Empty description="暂无数据" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { watch, nextTick, computed, ref, onUnmounted } from 'vue'
import { Loading, Empty } from 'vant'
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Array,
      default: () => []
    },
    echartsId: {
      type: String,
      default: 'main'
    },
    loading: {
      type: Boolean,
      default: true
    },
    unit: {
      type: String,
      default: '%'
    },
    height: {
      type: Number,
      default: 300
    }
  },
  components: {
    Loading,
    Empty
  },
  setup(props, { emit }) {
    let myChart = null
    const option = {
      grid: {
        left: '14%',
        right: 16,
        top: '10%',
        bottom: '10%'
      },
      tooltip: false,
      xAxis: {
        type: 'category',
        axisTick: {
          show: false
        }, // 设置刻度线和标签对齐
        axisLabel: {
          color: '#CCCCCC'
        },
        axisLine: {
          lineStyle: {
            color: '#E4E4E4'
          }
        },
        data: []
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#CCCCCC'
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E4E4E4',
            type: 'dashed'
          }
        }
      },
      series: [
        {
          data: [],
          type: 'bar',
          barWidth: 20,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#7EBAFC'
                },
                {
                  offset: 1,
                  color: '#0C86FE'
                }
              ],
              global: false
            }
          }
        }
      ]
    }
    const isEmpty = ref(false)

    watch(
      () => props.loading,
      async val => {
        if (!val) {
          await nextTick()
          if (!isEmpty.value) {
            myChart = echarts.init(document.getElementById(props.echartsId))
            myChart.setOption(option)
            myChart.on('click', 'series.bar', function (params) {
              emit('on-click', params.data)
            })
          }
        }
      }
    )

    const scrollWidth = computed(() => {
      if (props.isScroll) {
        return props.chartData[0].value.length * 70 + 'px'
      } else {
        return '100%'
      }
    })

    const chartHeight = computed(() => {
      return {
        '--height': props.height + 'px'
      }
    })

    watch(
      () => props.chartData,
      val => {
        if (val.length) {
          option.xAxis.data = val.map(item => item.name)
          option.series[0].data = val.map(item => item)
          console.log(myChart)
          if (myChart) {
            myChart.setOption(option)
          }
        } else {
          isEmpty.value = true
        }
      },
      { deep: true }
    )

    onUnmounted(() => {
      if (myChart) {
        myChart.clear()
        myChart.off('click')
      }
    })

    return { scrollWidth, chartHeight, isEmpty }
  }
}
</script>

<style lang="less" scoped>
.loading-wrap {
  width: 100%;
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.echarts {
  width: 100%;
  height: var(--height);
  display: flex;
  flex-direction: column;
  .unit-wrap {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    padding-left: 13px;
  }
  .scroll-wrap {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    .echart {
      width: 100%;
      height: 100%;
    }
  }
  .echart {
    width: 100%;
    height: 100%;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

// *环状饼图
<template>
  <div v-if="loading" class="loading-wrap" :style="chartHeight">
    <Loading vertical color="#0C86FE">加载中...</Loading>
  </div>
  <div class="echarts" :style="chartHeight" v-else>
    <div v-if="!isEmpty" class="echart" :id="echartsId"></div>
    <div v-if="isEmpty" class="empty">
      <Empty description="暂无数据" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { watch, nextTick, computed, ref, onUnmounted } from 'vue'
import { Loading, Empty } from 'vant'
export default {
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    echartsId: {
      type: String,
      default: 'main'
    },
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 300
    },
    center: {
      type: Array,
      default: () => ['50%', '43%']
    },
    legendSplice: {
      type: Number,
      default: 0
    }
  },
  components: {
    Loading,
    Empty
  },
  setup(props, { emit }) {
    let myChart = null

    const option = {
      tooltip: false,
      color: [
        '#FE8739',
        '#71B94F',
        '#4C81CF',
        '#969696',
        '#D1B45D',
        '#9861E4',
        '#F4667C',
        '#FBD34A',
        '#4AA7FC',
        '#13D0BA',
        '#FF5555',
        '#FE8739',
        '#FA9D58',
        '#FABF76',
        '#FAD494',
        '#FAD6B3',
        '#F9E8D1',
        '#F9FAF0',
        '#D9C774',
        '#BDBB8F',
        '#A1B1AB',
        '#8597C7',
        '#698DC3',
        '#4D83BF',
        '#3179BB',
        '#156FB7',
        '#005CAB',
        '#006CAE',
        '#007BAD',
        '#008BAB',
        '#009AA9'
      ],
      legend: {
        width: '290px',
        bottom: 0,
        left: 'center',
        icon: 'circle'
      },
      series: [
        {
          radius: ['45%', '60%'],
          center: props.center,
          type: 'pie',
          data: [],
          label: {
            show: true,
            formatter: '{c}' + '\n' + '{d}%',
            color: '#212325'
          },
          itemStyle: {
            borderRadius: 2,
            borderColor: '#fff',
            borderWidth: 5
          },
          labelLine: {
            minTurnAngle: 90
          }
        }
      ]
    }
    const isEmpty = ref(false)

    const chartHeight = computed(() => {
      return {
        '--height': props.height + 'px'
      }
    })

    watch(
      () => props.loading,
      async val => {
        if (!val) {
          await nextTick()
          if (!isEmpty.value) {
            myChart = echarts.init(document.getElementById(props.echartsId))
            myChart.setOption(option)
            myChart.on('click', 'series.pie', function (params) {
              console.log(params.data)
              emit('on-click', params.data)
            })
            myChart.on('legendselectchanged', params => {
              const data = props.chartData.find(item => item.name === params.name)
              if (data) {
                emit('on-click', data)
              }
            })
          }
        }
      }
    )

    watch(
      () => props.chartData,
      val => {
        if (val.length) {
          if (props.legendSplice > 0) {
            option.legend.data = val.slice(0, 5).map(item => item.name)
          }
          option.series[0].data = val
          if (myChart) {
            myChart.setOption(option)
          }
        } else {
          isEmpty.value = true
        }
      },
      { deep: true }
    )

    watch(
      () => props.center,
      val => {
        if (val.length) {
          option.series[0].center = val
        }
      },
      { deep: true }
    )

    onUnmounted(() => {
      if (myChart) {
        myChart.clear()
        myChart.off('click')
      }
    })

    return { chartHeight, isEmpty }
  }
}
</script>

<style lang="less" scoped>
.loading-wrap {
  width: 100%;
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.echarts {
  width: 100%;
  height: var(--height);
  display: flex;
  flex-direction: column;
  .echart {
    width: 100%;
    height: 100%;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<template>
  <div v-if="loading" class="loading-wrap" :style="chartHeight">
    <Loading vertical color="#0C86FE">加载中...</Loading>
  </div>
  <div class="echarts" :style="chartHeight" v-else>
    <div class="scroll-wrap" v-if="isScroll && !isEmpty">
      <div class="echart" :id="echartsId" :style="{ width: scrollWidth, height: height + 'px' }"></div>
    </div>
    <div v-if="!isScroll && !isEmpty" class="echart" :id="echartsId"></div>
    <div v-if="isEmpty" class="empty">
      <Empty description="暂无数据"/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { watch, nextTick, computed, ref, onUnmounted } from 'vue'
import { Loading, Empty } from 'vant'
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Array,
      default: () => ([])
    },
    echartsId: {
      type: String,
      default: 'main'
    },
    loading: {
      type: Boolean,
      default: true
    },
    unit: {
      type: String,
      default: '%'
    },
    height: {
      type: Number,
      default: 300
    }
  },
  components: {
    Loading,
    Empty
  },
  setup (props, { emit }) {
    let myChart = null
    const option = {
      grid: {
        left: '4%',
        right: '10%',
        top: 20,
        bottom: 20,
        containLabel: true
      },
      xAxis: [
        {
          type: 'value',
          max: function (value) {
            if (value.max > 10000) {
              return Math.ceil((value.max / 10000)) * 10000
            } else if (value.max > 100) {
              return Math.ceil((value.max / 100)) * 100
            } else {
              return Math.ceil(value.max / 10) * 10
            }
          },
          splitNumber: 5,
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            fontSize: '12',
            fontFamily: 'SourceHanSansCN-Normal',
            fontWeight: '400',
            color: '#B3C4C3'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E6F0EE'
            }
          },
          splitLine: {
            show: false
          }
        },
        {
          show: false
        }
      ],
      yAxis: {
        type: 'category',
        show: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#E6F0EE'
          }
        },
        data: []
      },
      series: [
        {
          show: true,
          type: 'bar',
          barWap: '200%',
          barWidth: '20px',
          barMinHeight: 40,
          itemStyle: {
            borderRadius: [0, 3, 3, 0],
            color: function (params) {
              const colorList = ['#FE8739', '#71B94F', '#4C81CF', '#969696', '#D1B45D', '#9861E4', '#F4667C', '#FBD34A', '#4AA7FC', '#13D0BA', '#FF5555']
              return colorList[params.dataIndex]
            }
          },
          label: {
            show: true,
            color: '#ffffff',
            fontFamily: 'PingFangSC-Medium',
            fontWeight: '500',
            position: 'insideRight'
          },
          // 数据
          data: []
        },
        {
          show: true,
          type: 'bar',
          barGap: '120%',
          barWidth: '0.000000001%',
          itemStyle: {
            borderRadius: 200,
            color: 'transparent'
          },
          label: {
            show: true,
            position: [0, '-20'],
            fontSize: 12,
            fontFamily: 'SourceHanSansCN-Norma',
            fontWeight: 400,
            rich: {
              top1: {
                color: '#FE8739'
              },
              top2: {
                color: '#71B94F'
              },
              top3: {
                color: '#4C81CF'
              },
              top4: {
                color: '#969696'
              },
              top5: {
                color: '#D1B45D'
              },
              top6: {
                color: '#9861E4'
              },
              top7: {
                color: '#F4667C'
              },
              top8: {
                color: '#FBD34A'
              },
              top9: {
                color: '#4AA7FC'
              },
              top10: {
                color: '#13D0BA'
              },
              top11: {
                color: '#FF5555'
              }
            },
            formatter: function (params) {
              const cn = new RegExp('[\u4E00-\u9FA5]+')
              var en = new RegExp('[A-Za-z]+')
              if (cn.test(params.data.name)) {
                return `{top${params.dataIndex + 1}|${params.data.name.length > 18 ? params.data.name.slice(0, 18) + '...' : params.data.name}}`
              }
              if (en.test(params.data.name)) {
                return `{top${params.dataIndex + 1}|${params.data.name.length > 30 ? params.data.name.slice(0, 30) + '...' : params.data.name}}`
              }
            }
          },
          data: []
        }
      ]
    }
    const isEmpty = ref(false)

    watch(
      () => props.loading,
      async val => {
        if (!val) {
          await nextTick()
          if (!isEmpty.value) {
            console.log(val)
            myChart = echarts.init(document.getElementById(props.echartsId))
            myChart.setOption(option)
            myChart.on('click', 'series.bar', function (params) {
              emit('on-click', params.data)
            })
          }
        }
      }
    )

    const scrollWidth = computed(() => {
      if (props.isScroll) {
        return props.chartData[0].value.length * 70 + 'px'
      } else {
        return '100%'
      }
    })

    const chartHeight = computed(() => {
      return {
        '--height': props.height + 'px'
      }
    })

    watch(
      () => props.chartData,
      val => {
        if (val.length) {
          option.series[0].data = val.map(item => item).reverse()
          option.series[1].data = val.map(item => item).reverse()
          console.log(myChart)
          if (myChart) {
            myChart.setOption(option)
          }
        } else {
          isEmpty.value = true
        }
      },
      { deep: true }
    )

    onUnmounted(() => {
      if (myChart) {
        myChart.clear()
        myChart.off('click')
      }
    })

    return { scrollWidth, chartHeight, isEmpty }
  }
}
</script>

<style lang="less" scoped>
.loading-wrap {
  width: 100%;
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.echarts {
  width: 100%;
  height: var(--height);
  display: flex;
  flex-direction: column;
  .unit-wrap {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(255,255,255, 0.6);
    padding-left: 13px;
  }
  .scroll-wrap {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    .echart {
      width: 100%;
      height: 100%;
    }
  }
  .echart {
    width: 100%;
    height: 100%;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

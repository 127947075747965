<template>
  <div class="tab-btn-wrap">
    <div
      class="tab-item"
      :class="{'tab-active': current === item.key }"
      v-for="item in tabList"
      :key="item.key"
      @click="clickHandle(item)"
    >
      {{item.name}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
      default: 'all'
    },
    tabList: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    clickHandle (item) {
      this.$emit('changeList', item.key)
    }
  }
}
</script>

<style lang="less" scoped>
.tab-btn-wrap {
  display: flex;
  // border: 1px solid #eaebed;
  // border-radius: 100px 100px 100px 100px;
  .tab-item {
    padding: 4px 16px;
    border: 1px solid #eaebed;
    border-right-color: transparent;
    cursor: pointer;
    font-size: 12px;
    color: #999999;
    &:first-of-type{
      border: 1px solid #eaebed;
      border-radius: 100px 0 0 100px;
      border-right-color: transparent;
    }
    &:last-of-type{
      border: 1px solid #eaebed;
      border-radius: 0 100px 100px 0;
    }
  }
  .tab-active {
    border-color: #007fff !important;
    color: #007FFF;
  }
}
</style>

<template>
  <div class="chart-wrap" ref="chartWrap"></div>
</template>

<script>
import * as echarts from 'echarts'
import provinceMap from './province.json'
import continentMap from './continent.json'
import countryMap from './country.json'
import ZhCnConuntry from './zh_conuntry.json'

export default {
  data() {
    return {
      chart: null,
      provinceMap: provinceMap,
      continentMap: continentMap,
      countryMap: countryMap
    }
  },
  props: {
    map: {
      type: String,
      default: 'country'
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    echarts.registerMap('province', this.provinceMap)
    echarts.registerMap('country', this.countryMap)
    echarts.registerMap('continent', this.continentMap)
    this.chart = echarts.init(this.$refs.chartWrap)
    this.setChart()
  },
  watch: {
    chartData: {
      handler() {
        this.setChart()
        this.chart.on('click', 'series.map', e => {
          if (e.data) {
            this.$emit('chartClick', e.data)
          }
        })
      },
      deep: true
    }
  },
  methods: {
    setChart() {
      if (this.chart) {
        const mapData = this.chartData.map(item => {
          return {
            ...item,
            name: ZhCnConuntry[item.name] || item.name,
            itemStyle: {
              color: '#2d8cf0',
              areaColor: '#2d8cf0'
            }
          }
        })
        this.chart.setOption({
          // tooltip: {
          //   show: true,
          //   trigger: 'item',
          //   formatter: function (e) {
          //     return `${e.name}<br/>  ${e.value || '-'}`
          //   }
          // },
          series: [
            {
              type: 'map',
              map: this.map,
              data: mapData,
              zoom: 1.1,
              coordinateSystem: 'geo',
              symbol: 'pin',
              selectedMode: 'multiple',
              emphasis: {
                itemStyle: {
                  color: '#2d8cf0',
                  areaColor: '#2d8cf0'
                }
              },
              select: {
                itemStyle: {
                  color: '#2d8cf0',
                  areaColor: '#2d8cf0'
                },
                label: {
                  show: true,
                  formatter: function (e) {
                    return `${e.name}${e.data ? '-' + e.data.value : ''}`
                  }
                }
              }
            }
          ]
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  width: 100%;
  height: 300px;
}
</style>

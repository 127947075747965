// *玉块图
<template>
  <div v-if="loading" class="loading-wrap" :style="chartHeight">
    <Loading vertical color="#0C86FE">加载中...</Loading>
  </div>
  <div class="echarts" :style="chartHeight" v-else>
    <div v-if="!isEmpty" class="echart" :id="echartsId"></div>
    <div v-if="isEmpty" class="empty">
      <Empty description="暂无数据"/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { watch, nextTick, computed, ref, onUnmounted } from 'vue'
import { Loading, Empty } from 'vant'
export default {
  props: {
    chartData: {
      type: Array,
      default: () => ([])
    },
    echartsId: {
      type: String,
      default: 'main'
    },
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 300
    }
  },
  components: {
    Loading,
    Empty
  },
  setup (props, { emit }) {
    let myChart = null

    const option = {
      polar: {
        radius: 100 // 10条数据的时候 190
      },
      angleAxis: {
        show: false,
        startAngle: 90,
        max: function (value) {
          return value.max * 4 / 3
        }
      },
      radiusAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          color: '#999999',
          fontSize: 14
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false,
          alignWithLabel: true
        },
        splitLine: {
          show: false
        },
        data: []
      },
      series: [
        {
          type: 'bar',
          coordinateSystem: 'polar',
          barWidth: 20,
          label: {
            show: true,
            color: '#333',
            position: 'top'
          },
          data: []
        }
      ]
    }

    const isEmpty = ref(false)

    const chartHeight = computed(() => {
      return {
        '--height': props.height + 'px'
      }
    })

    watch(
      () => props.loading,
      async val => {
        if (!val) {
          await nextTick()
          if (!isEmpty.value) {
            myChart = echarts.init(document.getElementById(props.echartsId))
            myChart.setOption(option)
            myChart.on('click', 'series.bar', function (params) {
              emit('on-click', params.data)
            })
          }
        }
      }
    )

    watch(
      () => props.chartData,
      val => {
        if (val.length) {
          const colorList = ['#26BFEF', '#4DCB73', '#FAD337']
          option.radiusAxis.data = val.map(item => item.name)
          option.series[0].data = val.map((item, index) => ({
            ...item,
            itemStyle: {
              color: colorList[index]
            }
          }))
        } else {
          isEmpty.value = true
        }
      },
      { deep: true }
    )

    onUnmounted(() => {
      if (myChart) {
        myChart.clear()
        myChart.off('click')
      }
    })

    return { chartHeight, isEmpty }
  }
}
</script>

<style lang="less" scoped>
.loading-wrap {
  width: 100%;
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.echarts {
  width: 100%;
  height: var(--height);
  display: flex;
  flex-direction: column;
  .echart {
    width: 100%;
    height: 100%;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

// *气泡图
<template>
  <div v-if="loading" class="loading-wrap" :style="chartHeight">
    <Loading vertical color="#0C86FE">加载中...</Loading>
  </div>
  <div class="echarts" :style="chartHeight" v-else>
    <div v-if="!isEmpty" class="echart" :id="echartsId"></div>
    <div v-if="isEmpty" class="empty">
      <Empty description="暂无数据"/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { watch, nextTick, computed, ref } from 'vue'
import { Loading, Empty } from 'vant'
export default {
  props: {
    chartData: {
      type: Array,
      default: () => ([])
    },
    echartsId: {
      type: String,
      default: 'main'
    },
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 300
    }
  },
  components: {
    Loading,
    Empty
  },
  setup (props) {
    let myChart = null
    const colorList = [
      { type: 'linear', x: 0, y: 0, x2: 0, y2: 1, colorStops: [{ offset: 0, color: 'rgba(12, 160, 254, 1)' }, { offset: 1, color: 'rgba(165, 232, 255, 1)' }] },
      { type: 'linear', x: 0, y: 0, x2: 0, y2: 1, colorStops: [{ offset: 0, color: 'rgba(160, 14, 243, 1)' }, { offset: 1, color: 'rgba(214, 144, 254, 1)' }] },
      { type: 'linear', x: 0, y: 0, x2: 0, y2: 1, colorStops: [{ offset: 0, color: 'rgba(254, 149, 95, 1)' }, { offset: 1, color: 'rgba(243, 95, 19, 1)' }] },
      { type: 'linear', x: 0, y: 0, x2: 0, y2: 1, colorStops: [{ offset: 0, color: 'rgba(249, 198, 31, 1)' }, { offset: 1, color: 'rgba(249, 230, 170, 1)' }] },
      { type: 'linear', x: 0, y: 0, x2: 0, y2: 1, colorStops: [{ offset: 0, color: 'rgba(8, 221, 190, 1)' }, { offset: 1, color: 'rgba(181, 255, 205, 1)' }] },
      { type: 'linear', x: 0, y: 0, x2: 0, y2: 1, colorStops: [{ offset: 0, color: 'rgba(249, 31, 68, 1)' }, { offset: 1, color: 'rgba(254, 189, 193, 1)' }] }
    ]
    const option = {
      tooltip: {},
      series: [
        {
          type: 'graph',
          data: [],
          // categories: categories,
          roam: true,
          layout: 'force',
          force: {
            // 斥力
            repulsion: 100,
            // 引力
            gravity: 0.2,
            // 值映射边长
            edgeLength: 200,
            // 是否开启动画
            layoutAnimation: true,
            // 减慢移动速度
            friction: 0.3
          },
          label: {
            show: true,
            position: 'inside',
            color: '#fff',
            fontSize: 14,
            width: 60,
            overflow: 'truncate'
          },
          itemStyle: {
            color: (params) => {
              return colorList[params.dataIndex % 6]
            }
          },
          scaleLimit: {
            min: 0.8,
            max: 3
          }
          // 拖拽点
          // draggable: true
        }
      ]
    }
    const isEmpty = ref(false)

    const chartHeight = computed(() => {
      return {
        '--height': props.height + 'px'
      }
    })

    // 归一并映射
    // 当前最大值 当前最小值 目标最大值 目标最小值  当前值
    const unitary = (xmax, xmin, ymax, ymin, num) => {
      return ymin + ((ymax - ymin) / (xmax - xmin) * (num - xmin))
    }

    watch(
      () => props.loading,
      async val => {
        if (!val) {
          await nextTick()
          if (!isEmpty.value) {
            myChart = echarts.init(document.getElementById(props.echartsId))
            myChart.setOption(option)
          }
        }
      }
    )

    watch(
      () => props.chartData,
      val => {
        if (val.length) {
          let min = 0
          let max = 0
          val.slice(0, 30).forEach(item => {
            if (item.value > max) {
              max = item.value
            }
            if (item.value < min) {
              min = item.value
            }
          })
          option.series[0].data = val.slice(0, 30).map(item => ({
            value: item.value,
            name: item.name,
            symbolSize: unitary(max, min, 86, 60, item.value)
          }))
        } else {
          isEmpty.value = true
        }
      },
      { deep: true }
    )

    return { chartHeight, isEmpty }
  }
}
</script>

<style lang="less" scoped>
.loading-wrap {
  width: 100%;
  height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.echarts {
  width: 100%;
  height: var(--height);
  display: flex;
  flex-direction: column;
  .echart {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="card-wrap">
    <div class="card-title">{{ title }}</div>
    <div class="body-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '发表趋势'
    },
    chartData: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="less" scoped>
.card-wrap {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 12px;
  .card-title {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #203040;
    box-sizing: border-box;
    // line-height: 14px;
    border-bottom: 1px solid #E4E4E4;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
